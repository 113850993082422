import { Component, Host, Prop, h } from '@stencil/core';

@Component({
  tag: 'x-field-helper',
  styleUrl: 'x-field-helper.sass',
})
export class XFieldHelper {
  @Prop({ reflect: true }) invalid: boolean;

  render() {
    return (
      <Host>
        <slot></slot>
      </Host>
    );
  }
}
