x-typography
  display: block
  min-width: 0

  &[inline]
    display: inline

  &[ellipsis]
    overflow: hidden
    text-overflow: ellipsis

  &[variant="empty"]
    font-size: 0
    line-height: 0
  &[variant="body2"]
    +preset(2)
  &[variant="body3"]
    +preset(3)

  &[color="dark"]
    color: var(--on-surface-dark)
  &[color="dark-50"]
    color: var(--on-surface-dark-50)
  &[color="red"]
    color: var(--on-surface-red)
  &[color="yellow"]
    color: var(--on-surface-yellow)
  &[color="green"]
    color: var(--on-surface-green)
  &[color="primary"]
    color: var(--primary)

  &[word-break="all"]
    word-break: break-all
  &[word-break="word"]
    word-break: break-word
