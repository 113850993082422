import { Component, Host, h, Prop } from '@stencil/core';

@Component({
  tag: 'x-stack',
  styleUrl: 'x-stack.sass',
})
export class XStack {
  @Prop({ reflect: true }) direction: 'row' | 'column' = 'column';
  @Prop({ reflect: true }) valign: string;
  @Prop({ reflect: true }) gap: string;

  render() {
    return (
      <Host>
        <slot></slot>
      </Host>
    );
  }
}
