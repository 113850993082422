import { Component, Host, State, Prop, Fragment, Event, EventEmitter, h, Element } from '@stencil/core';
import AirDatepicker from 'air-datepicker';
import localeEn from 'air-datepicker/locale/en';
import localeRu from 'air-datepicker/locale/ru';
import IMask from 'imask';
import clsx from 'clsx';

const pluginLocale = {
  en: localeEn,
  ru: localeRu,
};

@Component({
  tag: 'x-field-handle',
  styleUrl: 'x-field-handle.sass',
})
export class XFieldHandle {
  @Element() el: HTMLElement;

  @Prop() active: boolean;
  @Prop() fieldHandleId: string;
  @Prop() align: string;
  @Prop() caption: string;
  @Prop() placeholder: string;
  @Prop() mask: string;
  @Prop() type: string;
  @Prop() name: string;
  @Prop() value: any;
  @Prop() searchValue: string;
  @Prop() maxlength: number;
  @Prop() rows: number;
  @Prop() readonly: boolean;
  @Prop() disabled: boolean;
  @Prop() invalid: boolean;
  @Prop() datepicker: boolean = false;
  @Prop() autocomplete: boolean;
  @Prop() multiple: boolean = false;

  @State() focused: boolean = false;
  @State() focusValue: string;
  @State() focusedChipIndex: number;

  @Event({ bubbles: false }) activate: EventEmitter;
  @Event({ bubbles: false }) deactivate: EventEmitter;
  @Event({ bubbles: false }) addCustomChip: EventEmitter;
  @Event({ bubbles: false }) deleteChip: EventEmitter;
  @Event({ bubbles: false }) customValue: EventEmitter;

  maskInstance;
  datepickerInstance;
  input!: HTMLInputElement | HTMLTextAreaElement;

  private handleFocus = (e: any) => {
    if (e.type === 'focus') {
      this.activate.emit();

      if (this.mask) {
        this.focusValue = e.target.value;
      }
    } else {
      this.deactivate.emit();

      if (this.mask && !this.datepicker && e.target.value.trim() === '') {
        e.target.value = this.focusValue;
        this.focusValue = '';
      }
    }
  };

  private handleToggle = () => {
    if (!this.active) {
      this.activate.emit();
    } else {
      this.deactivate.emit();
    }
  };

  private handleControlClick = e => {
    if (e.target.tagName !== 'X-CHIP') {
      this.input.focus();
    }
  };

  private handleControlKeyUp = e => {
    const keyCode = e.keyCode || e.charCode;
    const chips = Array.from(this.el.querySelectorAll('x-chip'));
    const activeChipIndex = chips.indexOf(document.activeElement as any);

    // ←
    if (keyCode === 37) {
      if (document.activeElement === this.input && this.input.value.trim() === '') {
        chips.pop().focus();
        return;
      }

      if (activeChipIndex > 0) {
        chips[activeChipIndex - 1].focus();
      }
    }

    if (activeChipIndex !== -1) {
      // →
      if (keyCode === 39) {
        if (activeChipIndex < this.value.length - 1) {
          chips[activeChipIndex + 1].focus();
        } else {
          this.input.focus();
        }
      }

      // Backspace
      if (keyCode === 8) {
        this.deleteChip.emit(activeChipIndex);
        this.input.focus();
      }
    }
  };

  private handleACKeyDown = e => {
    const keyCode = e.keyCode || e.charCode;

    // Enter
    if (keyCode === 13 && e.target.value.trim() !== '') {
      e.preventDefault();
      this.addCustomChip.emit(e.target.value);
    }

    // Backspace
    if (keyCode === 8) {
      if (e.target.value.trim() === '') {
        this.deleteChip.emit(this.value.length - 1);
      }
    }
  };

  private handleACBlur = e => {
    if (!this.multiple && (!e.relatedTarget || e.relatedTarget.tagName !== 'X-SELECT-OPTION' || e.relatedTarget.closest('x-select') !== e.target.closest('x-select'))) {
      this.customValue.emit(e.target.value);
    }
  };

  private renderControl = () => {
    switch (this.type) {
      case 'select':
        return (
          <Fragment>
            {!this.autocomplete && (
              <button
                type="button"
                class="x-field-handle__toggle"
                onClick={this.handleToggle}
                title={this.value.map(item => item.label).join(', ')}
                disabled={this.disabled}
              ></button>
            )}
            <div
              class={clsx(
                'x-field-handle__control',
                { [`-align-${this.align}`]: !!this.align },
                { '-state-empty': !this.value.length },
                { '-state-autocomplete': this.autocomplete },
                { '-state-multiple': this.multiple },
              )}
              onClick={this.handleControlClick}
              onKeyUp={this.handleControlKeyUp}
            >
              <div class="x-field-handle__control-grid">
                <x-typography variant="empty">
                  <x-stack direction="row" gap="2xs" valign="middle">
                    {!this.value.length && !this.autocomplete && (
                      <x-typography variant="body3" ellipsis>
                        {this.placeholder || ''}
                      </x-typography>
                    )}

                    {!(this.autocomplete && !this.multiple) && (
                      <Fragment>
                        {!!this.value.length &&
                          this.value[0].chip &&
                          this.value.map((item, index) => (
                            <x-chip color={item.chip} focusable disabled={this.disabled} key={item.label} style={{ order: index + 1 }}>
                              {item.label}
                            </x-chip>
                          ))}

                        {!!this.value.length && !this.value[0].chip && (
                          <x-typography variant="body3" ellipsis>
                            {this.value.map((item, index, array) => item.label + (array.length - 1 === index ? '' : ', '))}
                          </x-typography>
                        )}
                      </Fragment>
                    )}

                    {this.autocomplete && (
                      <input
                        class="x-field-handle__control-input"
                        placeholder={!this.value.length ? this.placeholder : ''}
                        value={this.searchValue}
                        onFocus={this.handleFocus}
                        onBlur={this.handleACBlur}
                        onKeyDown={this.handleACKeyDown}
                        disabled={this.disabled}
                        ref={el => (this.input = el as HTMLInputElement)}
                      />
                    )}
                  </x-stack>
                </x-typography>

                {!!this.caption && <span>{this.caption}</span>}
              </div>
            </div>
          </Fragment>
        );

      case 'textarea':
        return (
          <textarea
            id={this.fieldHandleId}
            name={this.name}
            value={this.value}
            maxlength={this.maxlength}
            disabled={this.disabled}
            onFocus={this.handleFocus}
            onBlur={this.handleFocus}
            placeholder={this.placeholder}
            rows={this.rows}
            class={clsx('x-field-handle__control', { [`-align-${this.align}`]: !!this.align })}
            ref={el => (this.input = el as HTMLTextAreaElement)}
          ></textarea>
        );
      default:
        return (
          <input
            id={this.fieldHandleId}
            type={this.type}
            name={this.name}
            value={this.value}
            maxlength={this.maxlength}
            readonly={this.readonly}
            disabled={this.disabled}
            onFocus={this.handleFocus}
            onBlur={this.handleFocus}
            placeholder={this.placeholder}
            class={clsx('x-field-handle__control', { [`-align-${this.align}`]: !!this.align })}
            ref={el => (this.input = el as HTMLInputElement)}
          />
        );
    }
  };

  componentDidLoad() {
    if (this.input) {
      switch (this.mask) {
        case 'currentPage':
          this.maskInstance = IMask(this.input, {
            mask: Number,
            scale: 0,
            min: 1,
          });
          break;
        case 'date':
          this.maskInstance = IMask(this.input, {
            mask: Date,
            // min: new Date(2010, 0, 1),
            // max: new Date(),
            overwrite: true,
            pattern: '`d.`m.`Y',
            format: function (date) {
              const day = String('0' + date.getDate()).slice(-2);
              const month = String('0' + (date.getMonth() + 1)).slice(-2);
              const year = date.getFullYear();

              return [day, month, year].join('.');
            },
            parse: function (str) {
              const [day, month, year] = str.split('.');

              return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
            },
          });

          if (this.datepicker) {
            this.maskInstance.on('complete', () => {
              const [day, month, year] = this.maskInstance.value.split('.');
              const nextDate = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));

              this.datepickerInstance.selectDate(nextDate);
              this.datepickerInstance.setViewDate(nextDate);
            });
          }

          break;
        case 'time':
          this.maskInstance = IMask(this.input, {
            mask: 'hh:mm:ss',
            // lazy: false,
            overwrite: true,
            blocks: {
              hh: {
                mask: IMask.MaskedRange,
                from: 0,
                to: 23,
              },
              mm: {
                mask: IMask.MaskedRange,
                from: 0,
                to: 59,
              },
              ss: {
                mask: IMask.MaskedRange,
                from: 0,
                to: 59,
              },
            },
          });
          break;
        default:
          break;
      }

      if (this.datepicker) {
        const datepickerOptions = {
          prevHtml: '<svg><path d="M3.41658 7.41164L8.00488 12.0006L9.4191 10.5864L4.83161 5.99825L9.42096 1.41421L8.00675 0L2 5.99979L3.41421 7.41401L3.41658 7.41164Z" /></svg>',
          nextHtml:
            '<svg><path d="M8.58934 4.58899L4.00104 0L2.58682 1.41421L7.17431 6.00239L2.58496 10.5864L3.99917 12.0006L10.0059 6.00084L8.5917 4.58663L8.58934 4.58899Z" /></svg>',
          navTitles: {
            days: 'MMMM yyyy',
          },
          dateFormat: 'dd.MM.yyyy',
          keyboardNav: false,
          toggleSelected: false,
          autoClose: true,
          locale: pluginLocale[document.documentElement.lang || 'en'],
          container: this.el.closest('x-modal, x-app').querySelector('.air-datepicker-global-container'),
        } as any;

        if (this.value) {
          const startDate = this.value.split('.').reverse().join('.');

          if (!isNaN(new Date(startDate).getTime())) {
            datepickerOptions.startDate = startDate;
            datepickerOptions.selectedDates = [startDate];
          }
        }

        this.datepickerInstance = new AirDatepicker(this.input, datepickerOptions);
      }
    }
  }

  componentDidUpdate() {
    if (this.maskInstance) {
      this.maskInstance.updateValue();
    }
  }

  render() {
    return (
      <Host class="x-field-handle">
        <div
          class={clsx(
            'x-field-handle__body',
            `-type-${this.type}`,
            { [`-state-active`]: this.active },
            { [`-state-disabled`]: this.disabled },
            { [`-state-invalid`]: this.invalid },
          )}
        >
          <slot name="adornment-start"></slot>
          {this.renderControl()}
          <slot name="adornment-end"></slot>
        </div>
      </Host>
    );
  }
}
