import { Component, Host, h, Prop } from '@stencil/core';

@Component({
  tag: 'x-chip',
  styleUrl: 'x-chip.sass',
})
export class XChip {
  @Prop({ reflect: true }) disabled: boolean = false;
  @Prop({ reflect: true }) focusable: boolean = false;

  render() {
    return (
      <Host tabindex={this.disabled || !this.focusable ? null : '-1'}>
        <slot></slot>
      </Host>
    );
  }
}
