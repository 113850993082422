.x-field-handle
  display: block
  width: 100%
  &__body
    display: flex
    align-items: center
    position: relative
    width: 100%
    height: 40px
    box-sizing: border-box
    border: 1px solid var(--input-static)
    background-color: var(--input-white)
    color: var(--on-input-white-20)
    border-radius: var(--base-radius)
    &.-state-active
      border-color: var(--input-focus)
    &.-state-disabled
      background-color: var(--input-disabled-bg)
      border-color: var(--input-disabled)
      color: var(--on-input-dark-50)
      &,
      *
        cursor: not-allowed
      input
        color: var(--on-input-dark-50)
    &.-state-invalid
      border-color: var(--input-error)
    &.-type-textarea
      height: auto
  &__toggle
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    border: none
    background: none
    z-index: 2
    &[disabled]
      cursor: not-allowed
  textarea
    padding-top: 10px
    padding-bottom: 10px
    resize: vertical
    min-height: 38px
  &__control
    +preset(3)
    +placeholder(var(--on-input-dark-50))
    outline: none
    border: none
    background: none
    width: 100%
    height: 100%
    min-width: 0
    padding-left: 15px
    padding-right: 15px
    box-sizing: border-box
    color: currentColor
    &-grid
      display: flex
      align-items: center
      justify-content: space-between
      height: 100%
      white-space: nowrap
      span:first-child
        overflow: hidden
        text-overflow: ellipsis
    &-input
      +preset(3)
      +placeholder(var(--on-input-dark-50))
      color: var(--on-input-white-20)
      border: none
      background: none
      padding: 0
      width: 150px
      order: 1000
    &.-align-center
      padding-right: 0
      padding-left: 0
      text-align: center
    &.-state-empty
      color: var(--on-input-dark-50)
    &.-state-autocomplete
      cursor: text
