x-stack
  align-items: flex-start

  &[direction="column"]
    display: grid
  &[direction="row"]
    display: inline-grid
    grid-auto-flow: column

  &[valign="middle"]
    align-items: center

  @each $key, $value in $gaps
    &[gap="#{$key}"]
      grid-gap: #{$value}px
