import { Component, Host, Element, Prop, State, h } from '@stencil/core';

@Component({
  tag: 'x-input',
  styleUrl: 'x-input.sass',
})
export class XInput {
  @Element() el: HTMLElement;

  @Prop() align: string;
  @Prop() label: string;
  @Prop() fieldHandleId: string;
  @Prop() mask: string;
  @Prop() placeholder: string;
  @Prop() type: string;
  @Prop() name: string;
  @Prop() value: any;
  @Prop() rows: number;
  @Prop() maxlength: number;
  @Prop() disabled: boolean;
  @Prop() invalid: boolean;
  @Prop() autofocus: boolean = false;
  @Prop() helper: string;
  @Prop() datepicker: boolean = false;

  @State() isFocused: boolean = false;

  private activate = () => {
    this.isFocused = true;
  };

  private deactivate = () => {
    this.isFocused = false;
  };

  private focus = () => {
    setTimeout(() => {
      this.el.querySelector('input').focus();
    });
  };

  componentDidLoad() {
    if (this.autofocus) {
      this.focus();
    }
  }

  render() {
    return (
      <Host class="x-input">
        {!!this.label && <x-field-label disabled={this.disabled}>{this.label}</x-field-label>}
        <x-field-handle
          fieldHandleId={this.fieldHandleId}
          type={this.type}
          name={this.name}
          placeholder={this.placeholder}
          value={this.value}
          maxlength={this.maxlength}
          active={this.isFocused}
          disabled={this.disabled}
          invalid={this.invalid}
          align={this.align}
          mask={this.mask}
          rows={this.rows}
          onActivate={this.activate}
          onDeactivate={this.deactivate}
          datepicker={this.datepicker}
        >
          <slot></slot>
        </x-field-handle>
        {!!this.helper && <x-field-helper invalid={this.invalid}>{this.helper}</x-field-helper>}
      </Host>
    );
  }
}
