import { Component, Host, h, Prop } from '@stencil/core';

@Component({
  tag: 'x-field-label',
  styleUrl: 'x-field-label.sass',
})
export class XFieldLabel {
  @Prop({ reflect: true }) disabled: boolean;

  render() {
    return (
      <Host>
        <b>
          <slot></slot>
        </b>
      </Host>
    );
  }
}
