x-chip
  +preset(5)
  display: inline-block
  vertical-align: top
  border-radius: 4px
  color: var(--on-surface-dark)
  box-sizing: border-box
  padding: 1px 4px
  text-transform: uppercase
  height: 14px
  letter-spacing: 0
  white-space: nowrap
  position: relative
  overflow: hidden
  outline: none
  &[color="amber"]
    background-color: var(--table-tag-amber)
  &[color="cyan"]
    background-color: var(--table-tag-cyan)
  &[color="pink"]
    background-color: var(--table-tag-pink)
  &[color="blue-gray"]
    background-color: var(--table-tag-blue-gray)
  &[color="light-green"]
    background-color: var(--table-tag-light-green)
  &[color="indigo"]
    background-color: var(--table-tag-indigo)
  &:focus:after
    content: ""
    display: block
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: var(--table-tag-focus-20)
  &[disabled]
    opacity: 0.5
