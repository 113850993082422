import { Component, Host, h, Prop } from '@stencil/core';

@Component({
  tag: 'x-typography',
  styleUrl: 'x-typography.sass',
})
export class XTypography {
  @Prop({ reflect: true }) variant: string;
  @Prop({ reflect: true }) inline: boolean;
  @Prop({ reflect: true }) ellipsis: boolean;

  render() {
    return (
      <Host>
        <slot></slot>
      </Host>
    );
  }
}
